.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.section-heading {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
}
.content p {
  margin-bottom: 0.5rem;
}
.timeline-item::before {
  content: "";
  position: absolute;
  top: 0.5rem;
  left: -0.75rem;
  width: 0.5rem;
  height: 0.5rem;
  background: #3273dc;
  border-radius: 50%;
}
.timeline-item {
  position: relative;
  padding-left: 1rem;
  margin-bottom: 1rem;
}

@media print {
  .pagebreak { page-break-before: always; } /* page-break-after works, as well */
}
  .section-heading {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
  }
  .content p {
    margin-bottom: 0.5rem;
  }
  .timeline-item::before {
    content: "";
    position: absolute;
    top: 0.5rem;
    left: -0.75rem;
    width: 0.5rem;
    height: 0.5rem;
    background: #3273dc;
    border-radius: 50%;
  }
  .timeline-item {
    position: relative;
    padding-left: 1rem;
    margin-bottom: 1rem;
  }
.tag {
      /* margin: 3px; */
  }